﻿.pulse {
	position: relative;
	text-decoration: none;

	&:before,
	&:after {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
	}

	&:before {
		content: '';
		width: 100%;
		height: 100%;
		border-radius: 100%;
		box-shadow: 0 0 0 rgba($color-default, 0.75);
		transition: width 250ms ease-in-out, height 250ms ease-in-out, background-color 250ms ease-in-out;
	}

	&:after {
		color: #fff;
		font-size: 1.25rem;
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			&:before {
				animation: pulse 1500ms infinite;
			}
		}
	}

	.is-active & {
		&:before {
			background: $color-default;
			animation: none;
		}
	}
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba($color-default, 0.75);
	}

	70% {
		-webkit-box-shadow: 0 0 0 1.111rem rgba(204,169,44, 0);
	}

	100% {
		-webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes pulse {
	0% {
		-moz-box-shadow: 0 0 0 0 rgba($color-default, 0.75);
		box-shadow: 0 0 0 0 rgba($color-default, 0.75);
	}

	70% {
		-moz-box-shadow: 0 0 0 1.111rem rgba(204,169,44, 0);
		box-shadow: 0 0 0 1.111rem rgba(204,169,44, 0);
	}

	100% {
		-moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
		box-shadow: 0 0 0 0 rgba(204,169,44, 0);
	}
}

@keyframes MoveUpDown {
	0%, 100% {
		bottom: rem(20px);
	}

	50% {
		bottom: rem(60px);
	}
}

@keyframes FadeEffect {
	0%, 100% {
		opacity: .2;
	}

	50% {
		opacity: .6;
	}
}

@keyframes RevealCircle {
	0% {
		height: 100%;
	}

	100% {
		height: 0;
	}
}

@keyframes RotateCircle {
	0% {
		transform-origin: top;
		transform: rotate(0deg);
	}

	100% {
		transform-origin: top;
		transform: rotate(-180deg);
	}
}

@keyframes RotateCircleMobile {
	0% {
		transform-origin: top;
		transform: translateX(-50%) rotate(0deg);
	}

	100% {
		transform-origin: top;
		transform: translateX(-50%) rotate(-180deg);
	}
}