﻿.image {

	&.is-square {
		@include responsive-container-psudo(1, 1);
	}

	&.is-3by2 {
		@include responsive-container-psudo(3, 2);
	}

	&.is-16by9 {
		@include responsive-container-psudo(16, 9);
	}

	&.is-portrait {
		@include responsive-container-psudo(0.75, 1);
	}

	&.is-header {
		@include responsive-container-psudo(1.2, 1);
	}

	img, &div, video {
		width: 100%;
		height: 100%;
		object-fit: cover !important;
	}

	.video-button {
		&:before {
			animation: pulse 1500ms infinite;
		}

		transition: all .15s ease-in-out;
	}

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			.video-button {
				transform: translate(-50%,-50%) scale(1.15);
				transition: all .15s ease-in-out;
			}
		}
	}
}
