﻿header {
	position: relative;
	min-height: 100vh;

	nav {
		position: absolute;
		left: 50%;
		z-index: 1;
	}

	.header-content {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		min-height: 100vh;
		height: auto;
		background: #000;

		.spot {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			pointer-events: none;
			background: radial-gradient(circle at top left, hsla(0, 0%, 100%, .3), transparent 45%)
		}

		@include breakpoint(l) {
			height: 100vh;

			.header-text {
				height: 100%;
			}
		}

		.header-text {
			width: 100%;
			height: 85%;
			z-index: 2;

			.progress {
				position: absolute;
				width: 3px;
				height: 300px;
				padding-bottom: 100px;
				left: 8.6%;
				top: 50%;
				transform: translateY(-50%);
				z-index: 99;

				@include breakpoint(l) {
					top: unset;
					bottom: 3.415%;
					transform: unset;
				}

				&:before {
					position: absolute;
					content: "";
					background: #686867;
					width: 1px;
					height: calc(100% - 100px);
					top: 0;
					left: 50%;
					transform: translateX(-50%);
				}

				&:after {
					@include icon($icon-mouse);
					position: absolute;
					font-size: rem(46px);
					color: #686867;
					left: 50%;
					transform: translate(-50%, -50%);
					bottom: rem(11px);
				}

				.progress-bar {
					position: absolute;
					background: $color-default;
					width: 3px;
					height: 50px;
					left: 50%;
					transform: translateX(-50%);
					top: 0;
				}

				span {
					position: absolute;
					display: block;
					color: rgba(255,255,255, .2);
					font-size: rem(12px);
					font-weight: 500;
					text-transform: uppercase;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}

			ul {
				position: relative;
				height: 100%;

				li {
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%,-50%);

					p {
						font-weight: 300;
						font-size: rem(24px);
						line-height: rem(32px);
						color: $color-default;
						opacity: 1;
						margin: 0;

						@include breakpoint(l) {
							font-size: rem(32px);
							line-height: rem(40px);
						}

						&strong {
							font-weight: 500;
						}
					}

					h4 {
						opacity: 1;
						font-weight: 500;
						font-size: rem(28px);
						line-height: rem(40px);
						margin: rem(32px) 0 0 0;
					}

					&:not(:first-child) {
						opacity: 0;
						top: 60%;
					}
				}
			}
		}

		.header-media {
			position: absolute;
			bottom: 0;
			width: 100%;
			z-index: 0;

			@include breakpoint(l) {
				position: relative;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: end;
			}
		}

		@include breakpoint(l) {
			.header-text {
				width: 50%;
			}

			.header-media {
				width: 50%;
			}
		}
	}
}
