$icon-facebook: '\ea01';
$icon-instagram: '\ea02';
$icon-linkedin: '\ea03';
$icon-mouse: '\ea04';
$icon-play: '\ea05';
$icon-scroll-down: '\ea06';
$icon-scroll-up: '\ea07';
$icon-twitter: '\ea08';

@at-root {
	@font-face {
		font-family: icon;
		
		src: url('../../fonts/icon.woff2') format('woff2'), url('../../fonts/icon.woff') format('woff'); 
		font-style: normal;
		font-weight: normal;
		font-display: block;
	}
}

@mixin icon($content: none) {
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	font-family: icon;
	line-height: 1;
	text-transform: none;
	vertical-align: middle;
	speak: none;
	content: $content;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon:before {
	@include icon;
}

.icon-facebook:before { content: $icon-facebook; }
.icon-instagram:before { content: $icon-instagram; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-mouse:before { content: $icon-mouse; }
.icon-play:before { content: $icon-play; }
.icon-scroll-down:before { content: $icon-scroll-down; }
.icon-scroll-up:before { content: $icon-scroll-up; }
.icon-twitter:before { content: $icon-twitter; }
