﻿.button {
	@include font-button;
	position: relative;
	user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-webkit-tap-highlight-color: transparent;
	outline: none;
	z-index: 0;
	display: inline-block;
	padding: rem(22px) rem(48px);
	margin: rem(16px) 0 0 0;
	overflow: hidden;
	cursor: pointer;
	justify-content: center;
	align-items: center;
	border-radius: 36px;
	box-shadow: none !important;
	width: max-content;
	transition: all .25s ease;

	@media(hover: hover) and (pointer: fine) {
		&:hover {
			background-position: -100% 0;
		}
	}

	&::selection, &::-moz-selection {
		color: none;
		background: none;
	}

	&:focus {
		outline: 0 !important;
	}

	&.contact-btn {
		position: fixed;
		z-index: 1001;
		background: rgba(0,0,0, .5);

		@include breakpoint-max(l) {
			bottom: rem(32px);
			left: 50%;
			transform: translateX(-50%);
		}

		@include breakpoint(l) {
			right: 0;
			margin: rem(48px) rem(80px);
		}

		border: 1px solid #40474d;

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				color: $color-blue;
				border: 1px solid rgba($color-blue, 0.6);
				background: $color-default;
			}
		}
	}
}

.masonry {
	.button {
		margin-top: unset;
		margin-right: unset;
		font-weight: 400;
		font-size: rem(18px);
		line-height: rem(24px);
		color: $color-default;
		padding: rem(16px) rem(40px);
		background: $color-blue;
		border: 1px solid #40474d;

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				color: $color-blue;
				border: 1px solid rgba($color-blue, 0.6);
				background: $color-default;
			}
		}
	}
}

.buttons {
	margin-top: rem(-16px);
	padding-bottom: rem(63px);
	margin-right: rem(-30px);

	.button {
		margin-right: rem(30px);
	}

	.button:first-child {
		border: 1px solid #40474d;
		background: $color-blue;

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				color: $color-blue;
				border: 1px solid rgba($color-blue, 0.6);
				background: $color-default;
			}
		}
	}

	.button:nth-child(2) {
		border: 1px solid rgba($color-blue, 0.6);
		background: $color-default;
		color: $color-blue !important;

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				border: 1px solid #40474d;
				color: $color-default !important;
				background: $color-blue;
			}
		}
	}

	.button:only-child {
		border: 1px solid rgba($color-blue, 0.6) !important;
		color: $color-blue !important;
		background: $color-default;

		@media(hover: hover) and (pointer: fine) {
			&:hover {
				border: 1px solid #40474d !important;
				color: $color-default !important;
				background: $color-blue !important;
			}
		}
	}
}
