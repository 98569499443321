﻿.socials {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	z-index: 2;

	@include breakpoint-max(ts) {
		justify-content: center;
	}

	@include breakpoint-max(ll) {
		margin-bottom: rem(32px);

		li {
			margin-right: rem(24px);
		}
	}

	@include breakpoint(ll) {
		left: 51%;

		li {
			margin-right: rem(45px);
		}
	}

	a {
		position: relative;
		display: block;
		width: rem(29px);
		height: rem(29px);

		span {
			display: none;
		}

		&:before {
			position: absolute;
			content: "";
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			font-size: rem(29px);
			font-weight: 400;
			color: $color-blue;
		}

		&:hover, &:focus, &:active {
			@media(hover: hover) and (pointer: fine) {
				transform: scale(1.2);
			}
		}

		&.linkedin {
			&:before {
				@include icon($icon-linkedin);
			}
		}

		&.instagram {
			&:before {
				@include icon($icon-instagram);
			}
		}

		&.facebook {
			&:before {
				@include icon($icon-facebook);
			}
		}

		&.twitter {
			&:before {
				@include icon($icon-twitter);
			}
		}
	}
}
