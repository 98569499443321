﻿@use "sass:math";
%container,
.container {
	max-width: ($container-max-width + $grid-gap);
	padding: rem(72px) math.div($grid-gap, 2);

	@include breakpoint(l) {
		padding: rem(100px) math.div($grid-gap, 2);
	}

	margin: 0 auto;

	&.fluid {
		max-width: none;
		margin: 0;
	}

	&.small {
		max-width: ($container-small-max-width + $grid-gap);
	}

	&.med {
		max-width: ($container-med-max-width + $grid-gap);
	}

	&.large {
		max-width: ($container-large-max-width + $grid-gap);
	}
}
