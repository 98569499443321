﻿footer {
	position: relative;
	background: $color-default;
	color: $color-blue;

	&.trigger-circle {
		@include breakpoint(ll) {
			&:after {
				position: absolute;
				content: "";
				width: 20.89vw;
				height: 41.78vw;
				background: black;
				background: linear-gradient(to bottom, $color-blue 50%, $color-default 50%);
				pointer-events: none;
				top: 0;
				transform: translateY(-50%);
				right: 7.8125vw;
				-webkit-animation: RevealCircle .75s ease-in forwards;
				animation: RevealCircle .75s ease-in forwards;
			}

			&:before {
				position: absolute;
				content: "";
				background: url(../../images/circle-element-footer.svg) no-repeat;
				pointer-events: none;
				width: 20.89vw;
				height: 41.78vw;
				top: 0;
				transform: translateY(-50%);
				right: 7.8125vw;
			}
		}
	}

	.footer-scroll-btn {
		position: absolute;
		display: block;
		top: 0;
		left: 50%;
		transform: translate(-50%, -50%);
		width: rem(96px);
		height: rem(96px);
		background: $color-blue;
		border-radius: 50%;

		&:after {
			position: absolute;
			@include icon($icon-scroll-up);
			font-size: rem(34px);
			font-weight: 400;
			color: $color-default;
			opacity: .3;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			-webkit-animation: FadeEffect 2s ease-in-out infinite;
			animation: FadeEffect 2s ease-in-out infinite;
		}
	}

	.container {
		padding-bottom: rem(116px);

		@include breakpoint(l) {
			padding-bottom: 0;
		}

		.columns > .column:last-child {
			@include breakpoint(ll) {
				padding-left: 2rem;
				padding-top: 2rem;
			}
		}

		.column {
			position: relative;
		}

		h2 {
			&:before {
				background: url(../../images/heading-element-blue.svg);
			}
		}

		p {
			font-size: rem(32px) !important;
			font-weight: 300 !important;
			line-height: rem(35px) !important;
			opacity: .6 !important;
			margin-bottom: 1rem;
		}

		.linkedin {
			margin-bottom: rem(58px);
		}

		.footer-links {
			display: flex;
			flex-wrap: wrap;
			padding-top: 0;
			padding-bottom: 0;

			@include breakpoint-max(ts) {
				justify-content: center;
			}

			li {
				font-weight: 400;
				font-size: rem(14px);
				line-height: rem(14px);
				padding: rem(20px) 0;
				margin-right: rem(32px);
				opacity: .7;

				a {
					@include underline;
				}
			}
		}
	}
}
