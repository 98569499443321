﻿.masonry {
	@include breakpoint-max(ts) {
		.column:last-child {
			margin-bottom: 0 !important;
		}

		.logo-block {
			display: none;
		}
	}

	@include breakpoint(ts) {
		.container {
			padding: rem(72px) math.div($grid-gap, 2) rem(8px);
		}
	}

	@include breakpoint(l) {
		.container {
			padding: rem(100px) math.div($grid-gap, 2) rem(36px);
		}
	}

	@include breakpoint-max(l) {
		h3 {
			font-size: rem(32px);
			line-height: rem(32px);
		}
	}

	.columns {
		margin-top: -32px;
	}

	.column {
		display: block;
		border-top: 32px solid transparent !important;
		border-bottom: 32px solid transparent !important;
	}

	figure {
		position: relative;
		z-index: 1;

		figcaption {
			position: absolute;
			z-index: 2;
			top: 66px;
			left: 50%;
			transform: translateX(-50%);
			width: 70%;
			text-align: center;
		}
	}

	.text-content {
		position: relative;
		background: $color-default;
		color: $color-blue;
		padding: rem(40px) rem(32px);
		overflow: hidden;

		@include breakpoint(lm) {
			padding: rem(40px) rem(48px);
		}

		&.is-cta {
			background: rgba($color-default, 0.7);
			z-index: 1;

			&:after {
				position: absolute;
				content: "";
				background: url(../../images/beeldmerk.svg) no-repeat;
				pointer-events: none;
				width: rem(577px);
				height: rem(591px);
				top: rem(-79px);
				left: rem(-197px);
				z-index: -1;
			}
		}

		.date {
			display: block;
			font-size: rem(18px);
			font-weight: 400;
			margin-bottom: rem(18px);
		}

		p {
			font-size: rem(16px);
			line-height: rem(20px);
			font-weight: 400;
			opacity: .7;
		}

		p:last-of-type {
			margin-bottom: 0 !important;
		}

		.button {
			margin-top: rem(18px);
		}

		label {
			position: relative;
			margin-bottom: rem(9px);
			display: block;
		}

		.full-text {
			max-height: 0;
			overflow: hidden;
		}

		label:after {
			content: "Read more";
		}

		input[type="checkbox"]:checked ~ label {
			&:after {
				content: "Close text"
			}
		}

		input[type="checkbox"] {
			display: none;
		}

		input[type="checkbox"]:checked ~ div {
			//transition: all 0.45s ease-in !important;
		}
	}
}
