﻿.logo {
	position: absolute;
	display: block;
	width: rem(242px);
	height: rem(73px);
	text-indent: -99999px;
	z-index: 1;
	margin-top: rem(48px);
	left: 50%;
	transform: translateX(-50%);
	background: url(../../images/logo-white.svg) no-repeat;
	background-size: 100% !important;

	&.is-footer {
		position: relative;
		background: url(../../images/logo-blue.svg) no-repeat;

		@include breakpoint-max(ts) {
			margin-left: auto !important;
			margin-right: auto !important;
		}

		@include breakpoint-max(ll) {
			margin: unset;
			left: unset;
			transform: unset;
			margin-bottom: rem(32px);
		}

		@include breakpoint(ll) {
			left: 71%;
			transform: translateX(-50%);
			margin-bottom: rem(74px);
		}
	}
}
