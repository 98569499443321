﻿@import "fonts";
@import "icon";

// Primary colors
$color-default: #ffffff;
$color-blue: #000;

// Base
$font-text: "WorkSans", sans-serif;
$default-line-height: rem(32px);

// Container
$container-max-width: rem(1336px);
$container-small-max-width: rem(704px);
$container-med-max-width: rem(752px);
$container-large-max-width: rem(1336px);

// Grid
$grid-columns: 12;
$grid-gap: 6rem;
