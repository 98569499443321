﻿.content-block {
	position: relative;

	&.small {
		.columns {
			@include breakpoint(l) {
				width: 79%;
			}
		}
	}

	&.large {
		.columns {
			.column:first-child {
				@include breakpoint(l) {
					padding-right: 10%;
				}
			}
		}

		@include breakpoint(l) {
			.no-buttons {
				.text-content {
					p:last-child {
						margin-bottom: rem(36px) !important;
					}
				}
			}
		}
	}

	figure {
		height: 100%;
	}

	&.trigger-circle {

		@include breakpoint(ll) {
			&:after {
				position: absolute;
				content: "";
				width: 20.89vw;
				height: 41.78vw;
				background: $color-blue;
				pointer-events: none;
				bottom: 45%;
				right: 7.8125vw;
				-webkit-animation: RevealCircle 1.15s ease-in forwards;
				animation: RevealCircle 1.15s ease-in forwards;
				z-index: -1;
			}

			&:before {
				position: absolute;
				content: "";
				background: url(../../images/circle-element.svg) no-repeat;
				pointer-events: none;
				width: 16.712vw;
				height: 33.424vw;
				bottom: 45%;
				right: 7.8125vw;
				z-index: -1;
			}
		}
	}
}
