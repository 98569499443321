﻿$font-path: '../../fonts/';

@font-face {
	font-family: 'WorkSans';
	font-weight: 100;
	font-style: normal;
	src: url('#{$font-path}WorkSans-Thin.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'WorkSans';
	font-weight: 200;
	font-style: normal;
	src: url('#{$font-path}WorkSans-ExtraLight.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'WorkSans';
	font-weight: 300;
	font-style: normal;
	src: url('#{$font-path}WorkSans-Light.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'WorkSans';
	font-weight: 400;
	font-style: normal;
	src: url('#{$font-path}WorkSans-Regular.woff2') format('woff2');
	font-display: swap;
}

@font-face {
	font-family: 'WorkSans';
	font-weight: 600;
	font-style: normal;
	src: url('#{$font-path}WorkSans-SemiBold.woff2') format('woff2');
	font-display: swap;
}

@mixin font-text {
	font-family: $font-text;
	font-size: rem(24px);
	line-height: rem(26px);
	font-weight: 300;
	color: $color-default;
}

@mixin font-button {
	font-weight: 400;
}

